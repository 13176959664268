import {
  type TypedDocumentNode,
  type OperationVariables,
  type MutationUpdaterFunction,
  type DefaultContext,
  type ApolloCache,
} from "@apollo/client"
import {
  type MutateResult,
  type UseQueryOptions,
  useMutation,
  useQuery,
} from "@vue/apollo-composable"
import { type MaybeRef, computed, reactive, ref, unref, toRef } from "vue"

import type { BaseEntity } from "@/types"

import { type Maybe, type MutationRoot, type QueryRoot } from "@/graphql/types"
import { useHandleMutationErrors } from "@/utils/composables/useHandleMutationErrors"
import { useWhenResult } from "@/utils/misc"

export type EntityList<TEntity extends BaseEntity> = {
  __typename?: string
  items: TEntity[]
  page?: Maybe<number>
  total?: Maybe<number>
  limit?: Maybe<number>
}

export type BaseListResult<
  TEntity extends BaseEntity,
  TEntityKey extends keyof QueryRoot,
  TListEntities extends BaseEntity = TEntity,
> =
  | Record<TEntityKey, EntityList<TEntity>>
  | Partial<
      Record<Exclude<keyof QueryRoot, TEntityKey>, EntityList<Exclude<TListEntities, TEntity>>>
    >
  | undefined
// { [T in string]: EntityList<T extends TEntityKey ? TEntity : Exclude<TListEntities, TEntity>> }

export type BaseGetByIdResult<TEntity extends BaseEntity> =
  | Partial<Record<keyof QueryRoot, TEntity>>
  | undefined
export type BaseCreateResult<TEntity extends BaseEntity> =
  | Partial<Record<keyof MutationRoot, TEntity>>
  | undefined
export type BaseUpdateResult<TEntity extends BaseEntity> =
  | Partial<Record<keyof MutationRoot, TEntity>>
  | undefined
export type BaseRemoveResult = Partial<Record<keyof MutationRoot, number>> | undefined
export type BaseLinkResult = Partial<Record<keyof MutationRoot, number>> | undefined

export type GqlOperations<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TListVariables extends OperationVariables,
  TGetByIdResult extends BaseGetByIdResult<TEntity>,
  TGetByIdVariables extends OperationVariables,
  TCreateResult extends BaseCreateResult<TEntity>,
  TCreateVariables extends OperationVariables,
  TUpdateResult extends BaseUpdateResult<TEntity>,
  TUpdateVariables extends OperationVariables,
  TRemoveResult extends BaseRemoveResult,
  TRemoveVariables extends OperationVariables,
  TLinkResult extends BaseLinkResult = undefined,
  TLinkVariables extends OperationVariables = {},
  TListEntities extends BaseEntity = TEntity,
> = {
  readonly list: undefined extends TListResult
    ? undefined
    : MaybeRef<TypedDocumentNode<TListResult, TListVariables>>
  readonly getById: undefined extends TGetByIdResult
    ? undefined
    : TypedDocumentNode<TGetByIdResult, TGetByIdVariables>
  readonly create: undefined extends TCreateResult
    ? undefined
    : TypedDocumentNode<TCreateResult, TCreateVariables>
  readonly update: undefined extends TUpdateResult
    ? undefined
    : TypedDocumentNode<TUpdateResult, TUpdateVariables>
  readonly remove: undefined extends TRemoveResult
    ? undefined
    : TypedDocumentNode<TRemoveResult, TRemoveVariables>
  readonly link: undefined extends TLinkResult
    ? undefined
    : TypedDocumentNode<TLinkResult, TLinkVariables>
}

export type ResultMap<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TGetByIdResult extends BaseGetByIdResult<TEntity>,
  TCreateResult extends BaseCreateResult<TEntity>,
  TUpdateResult extends BaseUpdateResult<TEntity>,
  TRemoveResult extends BaseRemoveResult,
  TLinkResult extends BaseLinkResult = undefined,
  TListEntities extends BaseEntity = TEntity,
> = {
  getList: undefined extends TListResult ? undefined : (result: TListResult) => EntityList<TEntity>
  getById?: undefined extends TGetByIdResult ? undefined : (result: TGetByIdResult) => TEntity
  getCreated: undefined extends TCreateResult ? undefined : (result: TCreateResult) => TEntity
  getUpdated: undefined extends TUpdateResult ? undefined : (result: TUpdateResult) => TEntity
  getRemovedCount: undefined extends TRemoveResult ? undefined : (result: TRemoveResult) => number
  getLinkedCount: undefined extends TLinkResult ? undefined : (result: TLinkResult) => number
}

export type ListCacheReducer<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TResult,
  TVariables extends OperationVariables,
  TListEntities extends BaseEntity = TEntity,
> = (cachedQuery: TListResult, data: TResult, variables: TVariables) => TListResult

export type RemovedIdsMapper<TRemoveVariables extends OperationVariables> = (
  variables: TRemoveVariables
) => string[]

export type GetRemoveReducer<TRemoveResult, TRemoveVariables extends OperationVariables> = (
  mapRemovedIds: RemovedIdsMapper<TRemoveVariables>
) => MutationUpdaterFunction<TRemoveResult, TRemoveVariables, DefaultContext, ApolloCache<unknown>>

export type ApiParams<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TListVariables extends OperationVariables,
  TGetByIdResult extends BaseGetByIdResult<TEntity>,
  TGetByIdVariables extends OperationVariables,
  TCreateResult extends BaseCreateResult<TEntity>,
  TCreateVariables extends OperationVariables,
  TUpdateResult extends BaseUpdateResult<TEntity>,
  TUpdateVariables extends OperationVariables,
  TRemoveResult extends BaseRemoveResult,
  TRemoveVariables extends OperationVariables,
  TLinkResult extends BaseLinkResult = undefined,
  TLinkVariables extends OperationVariables = {},
  TListEntities extends BaseEntity = TEntity,
> = {
  typename: string
  operations: GqlOperations<
    TEntity,
    TEntityListKey,
    TListResult,
    TListVariables,
    TGetByIdResult,
    TGetByIdVariables,
    TCreateResult,
    TCreateVariables,
    TUpdateResult,
    TUpdateVariables,
    TRemoveResult,
    TRemoveVariables,
    TLinkResult,
    TLinkVariables,
    TListEntities
  >
  resultMap: ResultMap<
    TEntity,
    TEntityListKey,
    TListResult,
    TGetByIdResult,
    TCreateResult,
    TUpdateResult,
    TRemoveResult,
    TLinkResult,
    TListEntities
  >
  mapRemovedIds: RemovedIdsMapper<TRemoveVariables>
  listQueryVariables: MaybeRef<TListVariables>
  listQueryOptions?: MaybeRef<UseQueryOptions<TListResult, TListVariables>>
  mutationVariables?: MaybeRef<OperationVariables>
  getByIdAdditionalVariables?: MaybeRef<OperationVariables>
  handleAdditionalCacheRemoveUpdates?: MutationUpdaterFunction<
    TRemoveResult,
    TRemoveVariables,
    DefaultContext,
    ApolloCache<unknown>
  > | null
  beforeRefetch?: () => void | Promise<void>
}

export type Api<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TListVariables extends OperationVariables,
  TGetByIdResult extends BaseGetByIdResult<TEntity>,
  TGetByIdVariables extends OperationVariables,
  TCreateResult extends BaseCreateResult<TEntity>,
  TCreateVariables extends OperationVariables,
  TUpdateResult extends BaseUpdateResult<TEntity>,
  TUpdateVariables extends OperationVariables,
  TRemoveResult extends BaseRemoveResult,
  TRemoveVariables extends OperationVariables,
  TLinkResult extends BaseLinkResult = undefined,
  TLinkVariables extends OperationVariables = {},
  TListEntities extends BaseEntity = TEntity,
> = ReturnType<
  typeof useApi<
    TEntity,
    TEntityListKey,
    TListResult,
    TListVariables,
    TGetByIdResult,
    TGetByIdVariables,
    TCreateResult,
    TCreateVariables,
    TUpdateResult,
    TUpdateVariables,
    TRemoveResult,
    TRemoveVariables,
    TLinkResult,
    TLinkVariables,
    TListEntities
  >
>

export function useApi<
  TEntity extends BaseEntity,
  TEntityListKey extends keyof QueryRoot,
  TListResult extends BaseListResult<TEntity, TEntityListKey, TListEntities>,
  TListVariables extends OperationVariables,
  TGetByIdResult extends BaseGetByIdResult<TEntity>,
  TGetByIdVariables extends OperationVariables,
  TCreateResult extends BaseCreateResult<TEntity>,
  TCreateVariables extends OperationVariables,
  TUpdateResult extends BaseUpdateResult<TEntity>,
  TUpdateVariables extends OperationVariables,
  TRemoveResult extends BaseRemoveResult,
  TRemoveVariables extends OperationVariables,
  TLinkResult extends BaseLinkResult = undefined,
  TLinkVariables extends OperationVariables = {},
  TListEntities extends BaseEntity = TEntity,
>({
  typename,
  operations,
  resultMap,
  mapRemovedIds,
  listQueryVariables,
  listQueryOptions = ref({}),
  mutationVariables,
  getByIdAdditionalVariables,
  handleAdditionalCacheRemoveUpdates = null,
  beforeRefetch = () => {},
}: ApiParams<
  TEntity,
  TEntityListKey,
  TListResult,
  TListVariables,
  TGetByIdResult,
  TGetByIdVariables,
  TCreateResult,
  TCreateVariables,
  TUpdateResult,
  TUpdateVariables,
  TRemoveResult,
  TRemoveVariables,
  TLinkResult,
  TLinkVariables,
  TListEntities
>) {
  const { handleMutationErrors } = useHandleMutationErrors()

  const {
    result: listResult = ref<TListResult>(),
    loading: listLoading = ref(false),
    error = ref(),
    refetch = () => {},
    onResult = () => {},
  } = operations.list
    ? useQuery<TListResult, TListVariables>(operations.list, listQueryVariables, listQueryOptions)
    : {}

  const whenListResultAvailable = useWhenResult(listResult, true)

  const { mutate: createEntity = undefined, loading: createLoading = ref(false) } =
    operations.create
      ? useMutation<TCreateResult, TCreateVariables>(operations.create, {
          errorPolicy: "all",
          update: prepareListCacheReducer((cachedQuery, data) => {
            if (!resultMap.getCreated || !resultMap.getList) return cachedQuery

            const createdItem = resultMap.getCreated(data)
            const list = resultMap.getList(cachedQuery)
            list.items.push(createdItem)
            return cachedQuery
          }),
        })
      : {}

  const { mutate: updateEntity = undefined, loading: updateLoading = ref(false) } =
    operations.update
      ? useMutation<TUpdateResult, TUpdateVariables>(operations.update, {
          errorPolicy: "all",
          update: prepareListCacheReducer((cachedQuery, data) => {
            if (!resultMap.getUpdated || !resultMap.getList) return cachedQuery

            const updatedItem = resultMap.getUpdated(data)
            const list = resultMap.getList(cachedQuery)
            list.items = list.items.filter((item) => item.id !== updatedItem.id)
            list.items.push(updatedItem)
            return cachedQuery
          }),
        })
      : {}

  const { mutate: linkEntities = undefined, loading: linkLoading = ref(false) } = operations.link
    ? useMutation<TLinkResult, TLinkVariables>(operations.link)
    : {}

  const removeReducers: MutationUpdaterFunction<
    TRemoveResult,
    TRemoveVariables,
    DefaultContext,
    ApolloCache<unknown>
  >[] = []

  function addRemoveReducer(reducer: GetRemoveReducer<TRemoveResult, TRemoveVariables>) {
    removeReducers.push(reducer(mapRemovedIds))
  }

  const { mutate: removeEntities = undefined, loading: removeLoading = ref(false) } =
    operations.remove
      ? useMutation<TRemoveResult, TRemoveVariables>(operations.remove, {
          update: (cache, result, options) => {
            for (const reducer of removeReducers) reducer(cache, result, options)

            handleAdditionalCacheRemoveUpdates?.(cache, result, options)
            cache.gc()
          },
        })
      : {}

  const loading = computed(
    () =>
      listLoading.value ||
      createLoading.value ||
      removeLoading.value ||
      linkLoading.value ||
      updateLoading.value
  )

  function getById(variables: MaybeRef<TGetByIdVariables | undefined>) {
    if (!operations.getById) return undefined

    const refVariables = toRef(variables)
    const additionalVariables = toRef(getByIdAdditionalVariables)

    return useQuery<TGetByIdResult>(
      operations.getById,
      computed(() => ({
        ...(refVariables.value ? refVariables.value : {}),
        ...(additionalVariables.value ? additionalVariables.value : {}),
      })),
      computed(() => ({ enabled: !!refVariables.value }))
    )
  }

  async function create(payload: TCreateVariables): MutateResult<TCreateResult> {
    if (!createEntity) return null
    const refVariables = toRef(mutationVariables)
    const result = await createEntity({ ...payload, ...refVariables.value })
    if (handleMutationErrors(result)) return null
    return result
  }

  async function update(payload: TUpdateVariables): MutateResult<TUpdateResult> {
    if (!updateEntity) return null
    const refVariables = toRef(mutationVariables)
    const result = await updateEntity({
      ...payload,
      ...(refVariables.value ? refVariables.value : {}),
    })
    if (handleMutationErrors(result)) return null
    return result
  }

  async function link(payload: TLinkVariables): MutateResult<TLinkResult> {
    if (!linkEntities) return null

    const result = await linkEntities(payload)
    if (handleMutationErrors(result)) return null
    return result
  }

  async function remove(payload: TRemoveVariables): MutateResult<TRemoveResult> {
    if (!removeEntities) return null

    const result = await removeEntities(payload)
    if (handleMutationErrors(result)) return null
    return result
  }

  function prepareListCacheReducer<
    TResult,
    TVariables extends OperationVariables = OperationVariables,
  >(
    reduce: ListCacheReducer<
      TEntity,
      TEntityListKey,
      TListResult,
      TResult,
      TVariables,
      TListEntities
    >
  ): MutationUpdaterFunction<TResult, TVariables, DefaultContext, ApolloCache<unknown>> {
    return (cache, result, options) => {
      if (!result.data || !options.variables || !operations.list) return

      const cachedQuery = cache.readQuery<TListResult, TListVariables>({
        query: unref(operations.list),
        variables: unref(listQueryVariables),
      })

      if (!cachedQuery) return

      cache.writeQuery({
        // TODO: use cache.updateQuery instead?
        query: unref(operations.list),
        variables: unref(listQueryVariables),
        data: reduce(structuredClone(cachedQuery), result.data, options.variables),
      })
    }
  }

  const getListRemoveReducer =
    <
      TReducerRemoveResult extends Record<string, number>,
      TReducerRemoveVariables extends OperationVariables = OperationVariables,
    >(
      modify: (list: EntityList<TEntity>, removedIds: string[]) => void
    ) =>
    (mapRemovedIds: RemovedIdsMapper<TReducerRemoveVariables>) => {
      return prepareListCacheReducer(
        (cachedQuery, _data: TReducerRemoveResult, variables: TReducerRemoveVariables) => {
          if (!resultMap.getList) return cachedQuery

          const list = resultMap.getList(cachedQuery)
          modify(list, mapRemovedIds(variables))
          return cachedQuery
        }
      )
    }

  const getListFilterRemoveReducer = <
    TReducerRemoveResult extends Record<string, number>,
    TReducerRemoveVariables extends OperationVariables = OperationVariables,
  >(
    filterRemovedIds: (removedIds: string[]) => (item: TEntity) => boolean
  ) =>
    getListRemoveReducer<TReducerRemoveResult, TReducerRemoveVariables>(
      (list, removedIds) => (list.items = list.items.filter(filterRemovedIds(removedIds)))
    )

  async function refetchQuery() {
    if (beforeRefetch) await beforeRefetch()
    await refetch()
  }

  return reactive({
    typename,
    listResult,
    loading,
    error,
    refetch: refetchQuery,
    onResult,
    whenListResultAvailable,

    getById: operations.getById ? getById : undefined,
    create,
    update,
    remove,
    link: operations.link ? link : undefined,

    resultMap,
    prepareListCacheReducer,
    getListRemoveReducer,
    getListFilterRemoveReducer,
    addRemoveReducer,
  })
}
