import { type RouteRecordRaw } from "vue-router"

import { type AddressTypes, type Permission } from "@/types"

function generateAddressTypes(...types: (keyof AddressTypes)[]): AddressTypes {
  const addressTypes: AddressTypes = {}

  types.forEach((key) => {
    addressTypes[key] = true
  })

  return addressTypes
}

const routes: RouteRecordRaw[] = [
  {
    path: "/kinder",
    name: "ChildrenPage",
    component: () => import("./children/ChildrenPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:list", "child:unlock"],
    },
  },
  {
    name: "ChildCreate",
    path: "/kinder/erstellen",
    redirect: { name: "ChildCreateFormGeneral" },
    component: () => import("./children/views/ChildCreate.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:create", "child:unlock"],
    },
    children: [
      {
        path: "allgemein",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "general" },
        children: [
          {
            name: "ChildCreateFormGeneral",
            path: "",
            component: () => import("./children/forms/FormGeneral.vue"),
            children: [
              {
                name: "ChildCreateFormGeneralCreateCommune",
                path: "gemeinde",
                props: { listRouteName: "ChildCreateFormGeneral" },
                component: () => import("../stammdaten/commune/CommuneDialog.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "verschiedenes",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "miscellaneous" },
        children: [
          {
            name: "ChildCreateFormMiscellaneous",
            path: "",
            component: () => import("./children/forms/FormMiscellaneous.vue"),
            children: [
              {
                name: "CreateChildCreateInstitutionSpecialServiceDialog",
                path: "fachdienst/erstellen",
                props: {
                  listRouteName: "ChildCreateFormMiscellaneous",
                  addressTypes: generateAddressTypes("specialService"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    name: "ChildCreateFormMiscellaneousAddressFormCategoryDialog",
                    props: { listRouteName: "CreateChildCreateInstitutionSpecialServiceDialog" },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
              {
                path: "hausarzt/erstellen",
                props: {
                  listRouteName: "ChildCreateFormMiscellaneous",
                  addressTypes: generateAddressTypes("doctor"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: { listRouteName: "CreateChildCreateInstitutionSpecialServiceDialog" },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "impfstatus",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "vaccination" },
        children: [
          {
            name: "ChildCreateFormVaccination",
            path: "",
            component: () => import("./children/forms/FormVaccination.vue"),
          },
        ],
      },
      {
        name: "Bezugsperson1",
        path: "bezugsperson-1",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "firstGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardianFirst",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 0 },
            children: [
              {
                name: "CreateEmployerDialog0",
                path: "arbeitgeber/erstellen",
                props: {
                  listRouteName: "ChildCreateFormLocalGuardianFirst",
                  addressTypes: generateAddressTypes("employer"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: { listRouteName: "CreateEmployerDialog0" },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Bezugsperson2",
        path: "bezugsperson-2",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "secondGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardianSecond",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 1 },
            children: [
              {
                name: "CreateEmployerDialog1",
                path: "arbeitgeber/erstellen",
                props: {
                  listRouteName: "ChildCreateFormLocalGuardianSecond",
                  addressTypes: generateAddressTypes("employer"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: { listRouteName: "CreateEmployerDialog1" },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "bezugsperson-3",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "additionalGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardian",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 2 },
            children: [
              {
                name: "CreateEmployerDialog3",
                path: "arbeitgeber/erstellen",
                props: {
                  listRouteName: "ChildCreateFormLocalGuardian",
                  addressTypes: generateAddressTypes("employer"),
                },
                meta: {
                  requiredRoles: <Permission[]>["address:create"],
                },
                component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
                children: [
                  {
                    path: "kategorie",
                    props: { listRouteName: "CreateEmployerDialog3" },
                    component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                    meta: {
                      requiredRoles: <Permission[]>["category:create"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "vertrag",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "contract" },
        children: [
          {
            name: "ChildCreateFormContract",
            path: "",
            component: () => import("./children/forms/FormContract.vue"),
          },
        ],
      },
      {
        path: "gruppen",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "groups" },
        children: [
          {
            name: "ChildCreateFormGroups",
            path: "",
            component: () => import("./children/forms/FormGroups.vue"),
          },
        ],
      },
      {
        path: "buchung",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "reservation" },
        children: [
          {
            name: "ChildCreateFormReservation",
            path: "",
            component: () => import("./children/forms/FormReservation.vue"),
          },
        ],
      },
      {
        path: "gebuehren",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "fee" },
        children: [
          {
            name: "ChildCreateFormFee",
            path: "",
            component: () => import("./children/forms/FormFee.vue"),
          },
        ],
      },
      {
        path: "vertrag",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "contract" },
        children: [
          {
            name: "ChildCreatePrintContract",
            path: "",
            component: () => import("./children/forms/PrintContract.vue"),
          },
        ],
      },
      {
        name: "ChildCreateConclusion",
        path: "abschluss",
        component: () => import("./children/views/ChildCreateConclusion.vue"),
      },
    ],
  },
  {
    name: "ChildEdit",
    path: "/kinder/:id",
    redirect: (to) => ({ name: "ChildEditFormGeneral", params: { id: to.params.id } }),
    component: () => import("./children/views/ChildView.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:view", "child:unlock"],
    },
    children: [
      {
        name: "ChildEditFormGeneral",
        path: "allgemein",
        component: () => import("./children/forms/FormGeneral.vue"),
        children: [
          {
            name: "ChildEditFormGeneralCreateCommune",
            path: "gemeinde",
            props: { listRouteName: "ChildEditFormGeneral" },
            component: () => import("../stammdaten/commune/CommuneDialog.vue"),
          },
        ],
      },
      {
        name: "ChildEditFormMiscellaneous",
        path: "verschiedenes",
        component: () => import("./children/forms/FormMiscellaneous.vue"),
        children: [
          {
            path: "hausarzt/erstellen",
            props: {
              listRouteName: "ChildEditFormMiscellaneous",
              addressTypes: generateAddressTypes("doctor"),
            },
            component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["address:create"],
            },
            children: [
              {
                path: "kategorie",
                props: { listRouteName: "ChildEditInstitutionSpecialServiceDialog" },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
          {
            name: "ChildEditInstitutionSpecialServiceDialog",
            path: "fachdienst/erstellen",
            props: {
              listRouteName: "ChildEditFormMiscellaneous",
              addressTypes: generateAddressTypes("specialService"),
            },
            component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["address:create"],
            },
            children: [
              {
                path: "kategorie",
                name: "ChildEditFormMiscellaneousAddressFormCategoryDialog",
                props: { listRouteName: "ChildEditInstitutionSpecialServiceDialog" },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "ChildEditFormVaccination",
        path: "impfstatus",
        component: () => import("./children/forms/FormVaccination.vue"),
      },
      {
        name: "ChildEditFormLocalGuardianCreate",
        path: "bezugsperson/erstellen",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
        props: { index: 2 },
        children: [
          {
            name: "CreateEmployerDialog2",
            path: "arbeitgeber/erstellen",
            props: {
              listRouteName: "ChildEditFormLocalGuardianCreate",
              addressTypes: generateAddressTypes("employer"),
            },
            meta: {
              requiredRoles: <Permission[]>["address:create"],
            },
            component: () => import("./children/forms/dialogs/CreateAddressDialog.vue"),
            children: [
              {
                path: "kategorie",
                props: { listRouteName: "CreateEmployerDialog2" },
                component: () => import("../stammdaten/categories/CategoryDialog.vue"),
                meta: {
                  requiredRoles: <Permission[]>["category:create"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "ChildEditFormLocalGuardianEdit",
        path: "bezugsperson/:bid",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
      },
      {
        name: "ChildEditFormContract",
        path: "vertrag",
        component: () => import("./children/forms/FormContract.vue"),
      },
      {
        name: "ChildEditFormGroups",
        path: "gruppen",
        component: () => import("./children/forms/FormGroups.vue"),
      },
      {
        name: "ChildEditFormReservation",
        path: "buchung",
        component: () => import("./children/forms/FormReservation.vue"),
      },
      {
        name: "ChildEditFormFee",
        path: "gebuehren",
        component: () => import("./children/forms/FormFee.vue"),
      },
      {
        name: "ChildEditPrintContract",
        path: "vertrag-drucken",
        component: () => import("./children/forms/PrintContract.vue"),
      },
    ],
  },
  {
    path: "/kinder-ansicht",
    name: "ChildrenListView",
    component: () => import("./children/views/ChildrenListView.vue"),
  },
  {
    path: "/gruppen",
    name: "GroupPage",
    component: () => import("./groups/GroupPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:list", "group:unlock"],
    },
  },
  {
    path: "/gruppen/erstellen",
    name: "GroupViewCreate",
    component: () => import("./groups/GroupView.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:create", "group:unlock"],
    },
    children: [
      {
        path: "",
        name: "GroupFormCreate",
        component: () => import("./groups/GroupForm.vue"),
        children: [
          {
            path: "management/erstellen",
            name: "GroupCreateManagementFormCreate",
            props: { listRouteName: "GroupFormCreate" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
          {
            path: "management/:managementId",
            name: "GroupCreateManagementFormEdit",
            props: { listRouteName: "GroupFormCreate" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/gruppen/:id",
    name: "GroupViewEdit",
    redirect: (to) => ({ name: "GroupFormEdit", params: { id: to.params.id } }),
    component: () => import("./groups/GroupView.vue"),
    meta: {
      requiredRoles: <Permission[]>["group:view", "group:unlock"],
    },
    children: [
      {
        path: "",
        name: "GroupFormEdit",
        component: () => import("./groups/GroupForm.vue"),
        children: [
          {
            path: "management/erstellen",
            name: "GroupEditManagementFormCreate",
            props: { listRouteName: "GroupFormEdit" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
          {
            path: "management/:managementId",
            name: "GroupEditManagementFormEdit",
            props: { listRouteName: "GroupFormEdit" },
            component: () => import("./groups/ManagementDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["employee:update"],
            },
          },
        ],
      },
    ],
  },
]

export default routes
