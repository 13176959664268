import Button from "primevue/button"
import Chart from "primevue/chart"
import Checkbox from "primevue/checkbox"
import Chip from "primevue/chip"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Divider from "primevue/divider"
import FloatLabel from "primevue/floatlabel"
import Fluid from "primevue/fluid"
import IconField from "primevue/iconfield"
import InputIcon from "primevue/inputicon"
import InputNumber from "primevue/inputnumber"
import InputText from "primevue/inputtext"
import Listbox from "primevue/listbox"
import MultiSelect from "primevue/multiselect"
import ProgressBar from "primevue/progressbar"
import ProgressSpinner from "primevue/progressspinner"
import RadioButton from "primevue/radiobutton"
import Select from "primevue/select"
import Tab from "primevue/tab"
import TabList from "primevue/tablist"
import TabPanel from "primevue/tabpanel"
import TabPanels from "primevue/tabpanels"
import Tabs from "primevue/tabs"
import Tag from "primevue/tag"
import Toolbar from "primevue/toolbar"
import { VueDraggableNext } from "vue-draggable-next"

import type { App } from "@vue/runtime-core"

export default (app: App): void => {
  app.component("Button", Button)
  app.component("Chart", Chart)
  app.component("Checkbox", Checkbox)
  app.component("Chip", Chip)
  app.component("Column", Column)
  app.component("DataTable", DataTable)
  app.component("Dialog", Dialog)
  app.component("Divider", Divider)
  app.component("IconField", IconField)
  app.component("InputIcon", InputIcon)
  app.component("InputNumber", InputNumber)
  app.component("InputText", InputText)
  app.component("Listbox", Listbox)
  app.component("MultiSelect", MultiSelect)
  app.component("ProgressBar", ProgressBar)
  app.component("ProgressSpinner", ProgressSpinner)
  app.component("RadioButton", RadioButton)
  app.component("Select", Select)
  app.component("Tab", Tab)
  app.component("TabList", TabList)
  app.component("TabPanel", TabPanel)
  app.component("TabPanels", TabPanels)
  app.component("Tabs", Tabs)
  app.component("Tag", Tag)
  app.component("Toolbar", Toolbar)
  app.component("FloatLabel", FloatLabel)
  app.component("Fluid", Fluid)

  app.component("Draggable", VueDraggableNext)
}
