import { useQuery } from "@vue/apollo-composable"
import gql from "graphql-tag"
import { defineStore } from "pinia"
import { computed } from "vue"

import { useSessionStore } from "./session"

import ContextCustomerListQuery from "@/graphql/customer/ContextCustomerList.gql"
import CreateCustomerMutation from "@/graphql/customer/CreateCustomer.gql"
import CustomerByIdQuery from "@/graphql/customer/CustomerById.gql"
import RemoveCustomersMutation from "@/graphql/customer/RemoveCustomers.gql"
import UpdateCustomerMutation from "@/graphql/customer/UpdateCustomer.gql"
import {
  Table,
  type ProjectCustomer,
  type ProjectCustomerList,
  type QueryRootCustomersArgs,
  type MutationRootCreateCustomerArgs,
  type MutationRootRemoveCustomersArgs,
  type MutationRootUpdateCustomerArgs,
  type QueryRootCustomerByIdArgs,
  type StrictCustomerId,
} from "@/graphql/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useDataTableCache } from "@/utils/composables/useDataTableCache"
import { useTablePresetConfiguration } from "@/utils/composables/useTablePresetConfiguration"
import { useOnNextResult, useWhenResult } from "@/utils/misc"
import CustomerTableConfig from "@/views/admin/customer/CustomerTableConfig"

export type MappedProjectCustomer = ProjectCustomer & {}

export type CustomerListResult = { customers: ProjectCustomerList }
export type CustomerByIdResult = { customerById: ProjectCustomer }
export type CustomerCreateResult = { createCustomer: ProjectCustomer }
export type CustomerUpdateResult = { updateCustomer: ProjectCustomer }
export type CustomerRemoveResult = { removeCustomers: number }

export const customerResultMap = {
  getList: (result: CustomerListResult) => result.customers,
  getById: (result: CustomerByIdResult) => result.customerById,
  getCreated: (result: CustomerCreateResult) => result.createCustomer,
  getUpdated: (result: CustomerUpdateResult) => result.updateCustomer,
  getRemovedCount: (result: CustomerRemoveResult) => result.removeCustomers,
  getLinkedCount: undefined,
}

export const useCustomerStore = defineStore(Table.Customers, () => {
  const sessionStore = useSessionStore()

  const { result: contextCustomersResult } = useQuery<CustomerListResult>(
    ContextCustomerListQuery,
    () => ({}),
    () => ({ enabled: !!sessionStore.loggedIn && !!sessionStore.hasRoles?.(["customer:list"]) })
  )

  const onFirstResult = useWhenResult(contextCustomersResult)
  const onNextResult = () => useOnNextResult(contextCustomersResult)
  const contextCustomerList = computed(() => contextCustomersResult.value?.customers.items ?? [])

  const presetConf = useTablePresetConfiguration(
    Table.Customers,
    CustomerTableConfig.columnDefs,
    "CustomerPage"
  )

  const listQueryVariables = computed(() => ({}))
  const listQueryOptions = computed(() => ({
    enabled: sessionStore.hasRoles?.(["customer:list"]) && !!presetConf.initialColumnPresetsLoaded,
  }))

  const PresetBuiltQuery = computed(
    () => gql`
      query CustomerList($filter: ListFilter) {
        customers(filter: $filter) {
          page
          limit
          total
          items {
            id
            cid
            ${presetConf.queryFields} 
          }
        }
      }
    `
  )

  const api = useApi<
    ProjectCustomer,
    "customers",
    CustomerListResult,
    QueryRootCustomersArgs,
    CustomerByIdResult,
    QueryRootCustomerByIdArgs,
    CustomerCreateResult,
    MutationRootCreateCustomerArgs,
    CustomerUpdateResult,
    MutationRootUpdateCustomerArgs,
    CustomerRemoveResult,
    MutationRootRemoveCustomersArgs
  >({
    typename: "ProjectCustomer",
    operations: {
      list: PresetBuiltQuery,
      getById: CustomerByIdQuery,
      create: CreateCustomerMutation,
      update: UpdateCustomerMutation,
      remove: RemoveCustomersMutation,
      link: undefined,
    },
    resultMap: customerResultMap,
    mapRemovedIds: (variables) => variables.ids.map((id) => id.cid),
    listQueryVariables,
    listQueryOptions,
    beforeRefetch: () => presetConf.resetQuery(),
  })

  const toMapped = (customer: ProjectCustomer): MappedProjectCustomer => ({
    ...customer,
  })

  const list = computed<MappedProjectCustomer[]>(() =>
    (api.listResult?.customers.items ?? []).map(toMapped)
  )
  const dataTableCache = useDataTableCache(list)

  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.cid)))

  function mapViewRouteParams(item: MappedProjectCustomer): StrictCustomerId {
    return {
      cid: item.cid,
    }
  }

  return {
    api,
    list,
    dataTableCache,
    toMapped,
    mapViewRouteParams,

    onNextResult,
    onFirstResult,
    contextCustomersResult,
    contextCustomerList,

    presetConf,
  }
})
