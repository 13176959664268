import { useQuery } from "@vue/apollo-composable"
import gql from "graphql-tag"
import { defineStore } from "pinia"
import { computed } from "vue"

import { useCustomerStore } from "./customer"
import { useOrganizationStore } from "./organization"
import { useSessionStore } from "./session"

import ContextInstitutionListQuery from "@/graphql/institution/ContextInstitutionList.gql"
import CreateInstitutionMutation from "@/graphql/institution/CreateInstitution.gql"
import InstitutionByIdQuery from "@/graphql/institution/InstitutionById.gql"
import InstitutionSectionsByIdQuery from "@/graphql/institution/InstitutionSectionsById.gql"
import RemoveInstitutionsMutation from "@/graphql/institution/RemoveInstitutions.gql"
import UpdateInstitutionMutation from "@/graphql/institution/UpdateInstitution.gql"
import {
  type ProjectInstitution,
  type ProjectInstitutionList,
  type InstitutionType,
  Table,
  type QueryRootInstitutionsArgs,
  type MutationRootCreateInstitutionArgs,
  type MutationRootUpdateInstitutionArgs,
  type MutationRootRemoveInstitutionsArgs,
  type QueryRootInstitutionByIdArgs,
  type StrictInstitutionId,
  type InstitutionSection,
} from "@/graphql/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useDataTableCache } from "@/utils/composables/useDataTableCache"
import { useTablePresetConfiguration } from "@/utils/composables/useTablePresetConfiguration"
import { splitLongId } from "@/utils/entity"
import { useOnNextResult, useWhenResult } from "@/utils/misc"
import InstitutionTableConfig from "@/views/admin/institution/InstitutionTableConfig"

export type MappedProjectInstitution = ProjectInstitution & {}

export type InstitutionListResult = { institutions: ProjectInstitutionList }
export type InstitutionByIdResult = { institutionById: ProjectInstitution }
export type InstitutionCreateResult = { createInstitution: ProjectInstitution }
export type InstitutionUpdateResult = { updateInstitution: ProjectInstitution }
export type InstitutionRemoveResult = { removeInstitutions: number }

export type MappedInstitution = {
  id: string
  n: number
  iid: string
  name: string
  type: InstitutionType
  street: string
  zipCode: string
  district: string
  city: string
  country: string
  additionalAddress: string[]
  institutionNumber: string
  internalInstitutionNumber: string
  clientGroupId: string
  yearOfConstruction: string
  operatingPermitYear: string
  launchDate?: string
  terminationDate?: string
  communeId: string
  email: string
  phone: string
}

export type MappedInstitutionSection = {
  id: number
} & InstitutionSection

export const institutionResultMap = {
  getList: (result: InstitutionListResult) => result.institutions,
  getById: (result: InstitutionByIdResult) => result.institutionById,
  getCreated: (result: InstitutionCreateResult) => result.createInstitution,
  getUpdated: (result: InstitutionUpdateResult) => result.updateInstitution,
  getRemovedCount: (result: InstitutionRemoveResult) => result.removeInstitutions,
  getLinkedCount: undefined,
}
export type listVariablesType = QueryRootInstitutionsArgs & {
  includeCustomer: boolean
  includeOrganization: boolean
  includeCommune: boolean
}

export const useInstitutionStore = defineStore(Table.Institutions, () => {
  const sessionStore = useSessionStore()
  const customerStore = useCustomerStore()
  const organizationStore = useOrganizationStore()

  const { result: contextInstitutionsResult } = useQuery<InstitutionListResult>(
    ContextInstitutionListQuery,
    computed(() => ({
      // context is required for any new datatable entries to also be cached for this query
      context: sessionStore.customerFilter,
      includeCustomer: sessionStore.hasRoles(["customer:list"]),
      includeOrganization: sessionStore.hasRoles(["organization:list"]),
    })),
    computed(() => ({
      enabled: !!sessionStore.contextCustomerId && !!sessionStore.hasRoles?.(["institution:list"]),
    }))
  )

  const onFirstResult = useWhenResult(contextInstitutionsResult)
  const onNextResult = () => useOnNextResult(contextInstitutionsResult)
  const contextInstitutionList = computed(
    () => contextInstitutionsResult.value?.institutions.items ?? []
  )

  const { result: contextInstitutionSectionsResult } = useQuery<InstitutionByIdResult>(
    InstitutionSectionsByIdQuery,
    () => ({
      institutionId: sessionStore.strictInstitutionId,
    }),
    () => ({
      enabled: !!sessionStore.contextInstitutionId && sessionStore.hasRoles(["institution:view"]),
    })
  )

  const contextInstitutionSections = computed(
    () => contextInstitutionSectionsResult.value?.institutionById.sections
  )

  const presetConf = useTablePresetConfiguration(
    Table.Institutions,
    InstitutionTableConfig.columnDefs,
    "InstitutionPage"
  )
  const listQueryOptions = computed(() => ({
    enabled:
      sessionStore.hasRoles?.(["institution:list"]) && !!presetConf.initialColumnPresetsLoaded,
  }))

  const viewQueryVariables = computed(() => ({
    includeCustomer: sessionStore.hasRoles?.(["customer:view"]),
    includeOrganization: sessionStore.hasRoles?.(["organization:view"]),
    includeCommune: sessionStore.hasRoles?.(["commune:view"]),
  }))

  const listQueryVariables = computed(() => ({
    context: sessionStore.customerFilter,
    includeCustomer: sessionStore.hasRoles?.(["customer:list"]),
    includeOrganization: sessionStore.hasRoles?.(["organization:list"]),
    includeCommune: sessionStore.hasRoles?.(["commune:list"]),
  }))

  const PresetBuiltQuery = computed(
    () => gql`
      query InstitutionList($context: ContextFilter, $includeCustomer: Boolean!, $includeOrganization: Boolean!, $includeCommune: Boolean!) {
        institutions(context: $context) {
          page
          limit
          total
          items {
            id
            iid
            ${presetConf.queryFields}
            customer @include(if: $includeCustomer) {
              id
              cid
            }
            organization @include(if: $includeOrganization) {
              id
              oid
            }
            commune @include(if: $includeCommune) {
              id
              name
            }
          }
        }
      }
    `
  )

  const api = useApi<
    ProjectInstitution,
    "institutions",
    InstitutionListResult,
    QueryRootInstitutionsArgs,
    InstitutionByIdResult,
    QueryRootInstitutionByIdArgs,
    InstitutionCreateResult,
    MutationRootCreateInstitutionArgs,
    InstitutionUpdateResult,
    MutationRootUpdateInstitutionArgs,
    InstitutionRemoveResult,
    MutationRootRemoveInstitutionsArgs
  >({
    typename: "ProjectInstitution",
    operations: {
      list: PresetBuiltQuery,
      getById: InstitutionByIdQuery,
      create: CreateInstitutionMutation,
      update: UpdateInstitutionMutation,
      remove: RemoveInstitutionsMutation,
      link: undefined,
    },
    resultMap: institutionResultMap,
    mapRemovedIds: (variables) => variables.ids.map((id) => id.iid),
    listQueryVariables,
    listQueryOptions,
    mutationVariables: viewQueryVariables,
    getByIdAdditionalVariables: viewQueryVariables,
  })

  const toMapped = (institution: ProjectInstitution): MappedProjectInstitution => ({
    ...institution,
    additionalAddress: institution.additionalAddress ?? [],
  })

  const list = computed<MappedProjectInstitution[]>(() =>
    (api.listResult?.institutions.items ?? []).map(toMapped)
  )
  const dataTableCache = useDataTableCache(list)

  customerStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.customer?.cid))
  )
  organizationStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.organization?.oid))
  )
  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.iid)))

  function mapViewRouteParams(item: MappedProjectInstitution): StrictInstitutionId {
    const [cid, oid] = splitLongId(item.id)
    return {
      cid: item.customer?.cid ?? cid,
      oid: item.organization?.oid ?? oid,
      iid: item.iid,
    }
  }

  return {
    api,
    list,
    dataTableCache,
    toMapped,
    mapViewRouteParams,

    onNextResult,
    onFirstResult,
    contextInstitutionsResult,
    contextInstitutionList,
    contextInstitutionSections,

    presetConf,
  }
})
