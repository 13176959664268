import { useQuery } from "@vue/apollo-composable"
import gql from "graphql-tag"
import { defineStore } from "pinia"
import { computed } from "vue"

import { useCustomerStore } from "./customer"
import { useSessionStore } from "./session"

import ContextOrganizationListQuery from "@/graphql/organization/ContextOrganizationList.gql"
import CreateOrganizationMutation from "@/graphql/organization/CreateOrganization.gql"
import OrganizationByIdQuery from "@/graphql/organization/OrganizationById.gql"
import RemoveOrganizationsMutation from "@/graphql/organization/RemoveOrganizations.gql"
import UpdateOrganizationMutation from "@/graphql/organization/UpdateOrganization.gql"
import {
  type ProjectOrganization,
  type ProjectOrganizationList,
  type QueryRootOrganizationsArgs,
  type QueryRootOrganizationByIdArgs,
  type MutationRootCreateOrganizationArgs,
  type MutationRootUpdateOrganizationArgs,
  type MutationRootRemoveOrganizationsArgs,
  Table,
  type StrictOrganizationId,
} from "@/graphql/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useDataTableCache } from "@/utils/composables/useDataTableCache"
import { useTablePresetConfiguration } from "@/utils/composables/useTablePresetConfiguration"
import { splitLongId } from "@/utils/entity"
import { useOnNextResult, useWhenResult } from "@/utils/misc"
import OrganizationTableConfig from "@/views/admin/organization/OrganizationTableConfig"

export type MappedProjectOrganization = ProjectOrganization & {}

export type OrganizationListResult = { organizations: ProjectOrganizationList }
export type OrganizationCreateResult = { createOrganization: ProjectOrganization }
export type OrganizationUpdateResult = { updateOrganization: ProjectOrganization }
export type OrganizationRemoveResult = { removeOrganizations: number }
export type OrganizationByIdResult = { organizationById: ProjectOrganization }

export type MappedOrganization = {
  id: string
  n: number
  cid: string
  oid: string
  name: string
  numberOfMembers: string | number
  street: string
  zipCode: string
  district: string
  city: string
  country: string
  additionalAddress: string[]
  email: string
  phone: string
}

export const organizationResultMap = {
  getList: (result: OrganizationListResult) => result.organizations,
  getById: (result: OrganizationByIdResult) => result.organizationById,
  getCreated: (result: OrganizationCreateResult) => result.createOrganization,
  getUpdated: (result: OrganizationUpdateResult) => result.updateOrganization,
  getRemovedCount: (result: OrganizationRemoveResult) => result.removeOrganizations,
  getLinkedCount: undefined,
}

export const useOrganizationStore = defineStore(Table.Organizations, () => {
  const sessionStore = useSessionStore()
  const customerStore = useCustomerStore()

  const { result: contextOrganizationsResult, loading: contextOrganizationsLoading } =
    useQuery<OrganizationListResult>(
      ContextOrganizationListQuery,
      () => ({
        // context is required for any new datatable entries to also be cached for this query
        context: sessionStore.customerFilter,
        includeCustomer: sessionStore.hasRoles?.(["customer:view"]),
      }),
      () => ({
        enabled: !!sessionStore.contextCustomerId && !!sessionStore.hasRoles(["organization:list"]),
      })
    )

  const onFirstResult = useWhenResult(contextOrganizationsResult)
  const onNextResult = () => useOnNextResult(contextOrganizationsResult)
  const contextOrganizationList = computed(
    () => contextOrganizationsResult.value?.organizations.items ?? []
  )

  const presetConf = useTablePresetConfiguration(
    Table.Organizations,
    OrganizationTableConfig.columnDefs,
    "OrganizationPage"
  )

  const viewQueryVariables = computed(() => ({
    includeCustomer: sessionStore.hasRoles?.(["customer:view"]),
    includeInstitution: sessionStore.hasRoles?.(["institution:view"]),
  }))

  const listQueryVariables = computed(() => ({
    context: sessionStore.customerFilter,
    includeCustomer: sessionStore.hasRoles?.(["customer:list"]),
    includeInstitution: sessionStore.hasRoles?.(["institution:list"]),
  }))

  const listQueryOptions = computed(() => ({
    enabled:
      sessionStore.hasRoles?.(["organization:list"]) && !!presetConf.initialColumnPresetsLoaded,
  }))

  const PresetBuiltQuery = computed(
    () => gql`
      query OrganizationList($context: ContextFilter, $includeCustomer: Boolean!) {
        organizations(context: $context) {
          page
          limit
          total
          items {
            id
            oid
            ${presetConf.queryFields} 
            customer @include(if: $includeCustomer) {
              id
              cid
            }
          }
        }
      }
    `
  )

  const api = useApi<
    ProjectOrganization,
    "organizations",
    OrganizationListResult,
    QueryRootOrganizationsArgs,
    OrganizationByIdResult,
    QueryRootOrganizationByIdArgs,
    OrganizationCreateResult,
    MutationRootCreateOrganizationArgs,
    OrganizationUpdateResult,
    MutationRootUpdateOrganizationArgs,
    OrganizationRemoveResult,
    MutationRootRemoveOrganizationsArgs
  >({
    typename: "ProjectOrganization",
    operations: {
      list: PresetBuiltQuery,
      create: CreateOrganizationMutation,
      update: UpdateOrganizationMutation,
      remove: RemoveOrganizationsMutation,
      getById: OrganizationByIdQuery,
      link: undefined,
    },
    resultMap: organizationResultMap,
    mapRemovedIds: (variables) => variables.ids.map((id) => id.oid),
    listQueryVariables,
    listQueryOptions,
    mutationVariables: viewQueryVariables,
    getByIdAdditionalVariables: viewQueryVariables,
  })

  const toMapped = (organization: ProjectOrganization): MappedProjectOrganization => ({
    ...organization,
    additionalAddress: organization.additionalAddress ?? [],
  })

  const list = computed<MappedProjectOrganization[]>(() =>
    (api.listResult?.organizations.items ?? []).map(toMapped)
  )

  const dataTableCache = useDataTableCache(list)

  customerStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.customer?.cid))
  )
  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.oid)))

  function mapViewRouteParams(item: MappedProjectOrganization): StrictOrganizationId {
    return {
      cid: item.customer?.cid ?? splitLongId(item.id)[0],
      oid: item.oid,
    }
  }

  return {
    api,
    list,
    dataTableCache,
    toMapped,
    mapViewRouteParams,

    onNextResult,
    onFirstResult,
    contextOrganizationsResult,
    contextOrganizationList,
    contextOrganizationsLoading,

    presetConf,
  }
})
